import { TFF } from '@tff/types';

type SeatSegment = {
  code: string;
  segmentOnd: string;
};

type SeatObject = {
  [key: string]: SeatSegment;
};

/*
        Returns ssr "code" value from Metavalues array
    */

const findAirlineCode = (code: string, rows: TFF.MetaValue[]): string => {
  const foundItem = rows.find(i => i.code === code);
  return foundItem ? foundItem.airlineSsrCode : code;
};

/*
    Generates removeSsrIds object for ssr-update request payload
    */

const generateSsrPaxToRemove = (ancillaries: TFF.Ancillaries, paxId: string): string => {
  const foundKey = Object.keys(ancillaries).find((ancillaryKey): boolean => {
    const processedAncillaryKey: string = ancillaryKey.replace(/~\d+$/, '').replaceAll('~', '').replaceAll('-', '');
    return paxId === processedAncillaryKey;
  });
  return foundKey as string;
};

/*
    Returns ssr price to send back-end
    */

    const formatSsrPrice = (
      newPrice: number,
      originalPrice: number,
      adjustedSurcharge: number,
      originalAmount: number,
      newAmount: number,
    ): number => {
      if (originalAmount == 0) {
        return adjustedSurcharge > 0 ? newPrice / newAmount : 0;
      } else if (originalPrice == newPrice) {
        return (newPrice / newAmount) || (originalPrice / newAmount);
      } else if (originalPrice < newPrice) {
        return adjustedSurcharge == (newPrice - originalPrice)
          ? newPrice / newAmount
          : adjustedSurcharge == 0
          ? originalPrice / newAmount
          : ((originalPrice / originalAmount) + adjustedSurcharge) / newAmount;
      } else {
        return originalPrice / newAmount;
      }
    };
    

/*
        Generates paxData array for ssr-update request payload
        forms the array and its objects in the requested structure
    */

export const parsePaxData = (
  ssrsToAdd: { [paxId: string]: TFF.SsrDetails[] },
  outboundRaw: TFF.MetaValue[],
  inboundRaw: TFF.MetaValue[],
  Ancillaries: TFF.Ancillaries,
  seatAncillary?: { [paxId: string]: TFF.SeatDetails[] },
  surchargeValues?: {
    [key: string]: string;
  },
  newOnds?: {
    Outbound: string;
    Inbound: string;
  },
) => {
  const paxData: TFF.PaxData[] = [];
  const seats = seatAncillary ?? {};
  Object.keys(ssrsToAdd).forEach(paxId => {
    if (ssrsToAdd[paxId].length > 0) {
      let addedSsrs: TFF.SsrDetails[] = [];
      let addedSeats: TFF.SeatDetails[] = [];
      let removedSsrs: string[] = [];
      ssrsToAdd[paxId].forEach(ssr => {
        if (ssr.code.includes('remove') && !ssr.code.includes('BA0')) {
          const processPaxId = `${ssr.segmentOnd}${paxId}${findAirlineCode(
            ssr.code.replace('-remove', ''),
            outboundRaw ?? inboundRaw,
          )}`.replaceAll('-', '');
          removedSsrs.push(generateSsrPaxToRemove(Ancillaries, processPaxId));
        } else if (!ssr.code.includes('BA0')) {
          if (surchargeValues) {
            const isOutbound = ssr.segmentOnd.replaceAll('-', '') === newOnds?.Outbound;
            const arrayToSearch = isOutbound ? outboundRaw : inboundRaw;
            
            const newAncillaryPrice: number = arrayToSearch
            .filter(metaValue => metaValue.code === ssr.code)
            .map(metaValue => metaValue.price.amount)[0];
            
            const regexForKeys = new RegExp(`^${ssr.segmentOnd}-${ssr.code}-\\d+-\\d+$`);
            
            const keyWithNumber = Object.keys(surchargeValues).find(key => regexForKeys.test(key));
            if (keyWithNumber) {
              const originalPrice = Object.values(Ancillaries).filter(i => i.SegmentOnd == ssr.segmentOnd && i.Type == ssr.airlineSsrCode || i.TfaDetails?.Code == ssr.code)[0].TotalAmount ?? 0;
              const [originalSsrAmount, newSsrAmount] = [...keyWithNumber.split('-')].reverse();

              const formattedSurcharge = Number(
                  (parseInt(surchargeValues[keyWithNumber])).toFixed(2),
                );
                ssr.priceToCharge = Number(formatSsrPrice(newAncillaryPrice * parseInt(newSsrAmount), originalPrice  * parseInt(originalSsrAmount), formattedSurcharge,parseInt(originalSsrAmount),  parseInt(newSsrAmount)).toFixed(2));
              } 
          }
          addedSsrs.push(ssr);
        }
      });
      if (seats[paxId]) {
        if (surchargeValues) {
          //@ts-ignore
          seats[paxId].priceToCharge = parseInt(surchargeValues[`${seats[paxId].segmentOnd}-${seats[paxId].code}`]);
        }
        //@ts-ignore
        addedSeats.push(seats[paxId] ?? {});
      }
      paxData.push({
        paxId,
        removeSsrIds: removedSsrs,
        addSsrs: addedSsrs,
        addSeats: addedSeats,
      });
    }
  });
  return paxData;
};
